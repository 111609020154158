import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import icon1 from '../../Images/Setting_line_light.png';
import icon2 from '../../Images/Code.png';
import icon3 from '../../Images/Date_fill.png';

import './topcard.css';
export default function Card({ Data }) {
  const { title, description, icon } = Data;
  return (
    <Grid item xs={12} md={4} lg={4}>
      <div className="card1">
        <img className="iconsFix" src={icon} height="48.33px" width="48.33px" />

        <Typography className="t1">{title}</Typography>

        <p className="pMar">{description}</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer'
          }}
        >
          <ArrowBackIcon
            style={{
              color: '#d30e28',
              marginTop: '-0.5px'
            }}
          />
          <Typography className="t2red">Learn more</Typography>{' '}
        </div>
      </div>
    </Grid>
  );
}
