import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Card from './card';
import './plans.css';
export default function plans() {
  const Plans = [
    {
      type: 'Premium',
      price: '85',
      forms: '100',
      submissions: '10K',
      projects: 'Unlimited'
    },
    {
      type: 'Business',
      price: '18',
      forms: '20',
      submissions: '2K',
      projects: '10'
    },
    {
      type: 'Standard',
      price: '8',
      forms: '5',
      submissions: '5',
      projects: '3'
    }
  ];

  return (
    <Container>
      <Grid container justify="center" style={{ marginTop: '80px' }}>
        <Grid item>
          <Typography className="typo3">Weblobster Plans</Typography>
          <p className="alignCenter">
            Whether you're building a personal website or managing client
            projects, Weblobster has an affordable plan to meet your needs.
          </p>
        </Grid>
      </Grid>
      <Grid container justify="space-evenly" style={{ marginTop: '20px' }}>
        {Plans.map((plan) => (
          <Card plans={plan} />
        ))}
      </Grid>
    </Container>
  );
}
