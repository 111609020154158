import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import './plans.css';

export default function card({ plans }) {
  const { forms, price, projects, submissions, type } = plans;

  return (
    <Grid item item xs={12} md={3} lg={3}>
      <div className="card2">
        <Typography className="t2">{type}</Typography>

        <p>
          <span className="p1">${price}</span>/mo
        </p>
        <p className="planP">{forms} Forms</p>
        <p className="planP"> {submissions} Submissions</p>
        <p className="planP">{projects} Projects</p>
        <Button
          variant="contained"
          fullWidth
          style={{
            // fontFamily: 'inter',
            backgroundColor: '#E5122E',
            color: 'white',
            // fontSize: '12px',
            textAlign: 'center',
            // height: '44px',
            fontFamily: 'Inter',
            // fontStyle: 'normal',
            // fontWeight: 'bold',
            // fontSize: '20px',
            lineHeight: '24px',
            textTransform: 'capitalize'
          }}
        >
          <b> Select</b>
        </Button>
      </div>
    </Grid>
  );
}
