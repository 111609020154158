import React from "react";
import { Grid, Container, Typography, Box, Button } from "@material-ui/core";
import "./bigcard2.css";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import emailjs from "emailjs-com";
import { Formik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";

import * as Yup from "yup";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  roots: {
    display: "flex",
    marginLeft: "15px",
  },

  ErrorClr: {
    color: "red",
    marginTop: "5px",
  },
}));
export default function BigCard2() {
  const classes = useStyles();
  const [disable, setDisable] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  function sendEmail(values) {
    setDisable(true);
    setLoader(true);
    console.log("Values: ", values);

    let service = process.env.serviceId;
    let template = process.env.templateId;
    let user = process.env.userId;

    if (process.env.NODE_ENV === "production") {
      service = process.env.serviceIdProd;
      template = process.env.templateIdProd;
      user = process.env.userIdProd;
    }

    emailjs
      .send(service, template, values, user)
      .then((res) => {
        console.log(res);
        // enqueueSnackbar('Form Sumbitted Successfully', {
        //   anchorOrigin: {
        //     horizontal: 'right',
        //     vertical: 'top'
        //   },
        //   variant: 'success'
        // });
        setAlert(true);
        setDisable(false);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }
  const emailSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
  });

  return (
    <Container>
      <Grid
        container
        justify="center"
        // style={{ marginTop: '100px' }}
        className="big2"
      >
        <Grid item style={{ marginTop: "40px" }}>
          <Typography className="typo6">
            Create a working form in seconds.
          </Typography>
          <p style={{ color: "white", textAlign: "center" }}>
            Grab your form code and see form submissions in under a minute.
          </p>
        </Grid>
        <Formik
          initialValues={{ firstName: "", lastName: "", email: "", phone: "" }}
          validationSchema={emailSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            try {
              sendEmail(values);

              setSubmitting(false);
              resetForm({
                values: "",
              });
            } catch (e) {
              console.log(e);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              {/* <div onSubmit={sendEmail}> */}
              <Grid
                container
                justify="center"
                //   style={{ marginTop: '-50px' }}
                item
                xs={10}
                spacing={2}
              >
                <Grid item xs={12} md={5} lg={5}>
                  <TextField
                    className="txtF"
                    id="outlined-basic"
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    //   label="FirstName"
                    placeholder="First Name"
                    variant="outlined"
                    fullWidth
                  />
                  <Box component="span" className={classes.ErrorClr}>
                    {errors.firstName && touched.firstName && errors.firstName}
                  </Box>
                </Grid>
                <Grid item item xs={12} md={5} lg={5}>
                  <TextField
                    className="txtF"
                    id="outlined-basic"
                    name="lastName"
                    type="text"
                    //   label="Last Name"
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  <Box component="span" className={classes.ErrorClr}>
                    {errors.lastName && touched.lastName && errors.lastName}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                style={{ marginTop: "10px" }}
                item
                xs={10}
                spacing={2}
              >
                <Grid item item xs={12} md={5} lg={5}>
                  <TextField
                    className="txtF"
                    id="outlined-basic"
                    name="email"
                    type="email"
                    //   label="FirstName"
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <Box component="span" className={classes.ErrorClr}>
                    {errors.email && touched.email && errors.email}
                  </Box>
                </Grid>
                <Grid item item xs={12} md={5} lg={5}>
                  <TextField
                    className="txtF"
                    id="outlined-basic"
                    name="phone"
                    onInput={(e) => onlyNumbers(e)}
                    //   label="Phone Number"
                    placeholder="Phone Number"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  <Box component="span" className={classes.ErrorClr}>
                    {errors.phone && touched.phone && errors.phone}
                  </Box>
                </Grid>
              </Grid>
              <Grid container xs={12} justify="center">
                <Button
                  variant="contained"
                  type="submit"
                  // fullWidth
                  style={{
                    backgroundColor: disable ? "#561010" : "#E5122E",
                    color: "white",
                    textAlign: "center",
                    height: "44px",
                    width: "275px",
                    marginTop: "40px",
                    marginBottom: "50px",
                    color: "white",
                    textAlign: "center",
                    fontFamily: "Inter",
                    lineHeight: "24px",
                    textTransform: "capitalize",
                  }}
                  disabled={disable}
                >
                  {loader ? <b>Please Wait...</b> : <b> Submit</b>}{" "}
                  {loader ? (
                    <div className={classes.roots}>
                      <CircularProgress
                        size="10px"
                        style={{ color: "white" }}
                      />
                    </div>
                  ) : null}
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
        <SweetAlert
          info
          title="Info!"
          show={alert}
          onConfirm={() => setAlert(false)}
        >
          Please check your Email!
        </SweetAlert>
        {/* </div> */}
      </Grid>
    </Container>
  );
}
