import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import amazon from '../../Images/v2_261.png';
import adobe from '../../Images/v2_262.png';
import wal from '../../Images/v2_263.png';
import tmob from '../../Images/v2_264.png';
import tela from '../../Images/v2_265.png';
import readmea from '../../Images/v2_266.png';
import pga from '../../Images/v2_267.png';
import openai from '../../Images/v2_268.png';
import ibm from '../../Images/v2_269.png';

import './bigCard.css';
export default function bigCrad() {
  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        className="big"
        // style={{ marginTop: '-260px' }}
      >
        <Grid
          item
          conatiner
          justify="center"
          xs={12}
          md={5}
          lg={5}
          style={{ marginLeft: '25px', textAlign: 'center' }}
        >
          <Grid item container style={{ marginBottom: '60px' }}>
            <img
              src={amazon}
              className="marginTop"
              width="164px"
              height="53px"
            />
            <img
              src={adobe}
              className="marginTop"
              width="164px"
              height="53px"
            />
            <img src={wal} className="marginTop" width="164px" height="53px" />
            <img src={tmob} className="marginTop" width="164px" height="53px" />
            <img src={tela} className="marginTop" width="164px" height="53px" />
            <img
              src={readmea}
              className="marginTop"
              width="164px"
              height="53px"
            />
            <img src={pga} className="marginTop" width="164px" height="53px" />
            <img
              src={openai}
              className="marginTop"
              width="164px"
              height="53px"
            />
            <img src={ibm} className="marginTop" width="164px" height="53px" />
          </Grid>
          {/* <Grid item container style={{ marginBottom: '60px' }}>
           
          </Grid> */}
          {/* <Grid item container style={{ marginBottom: '60px' }}>
           
          </Grid> */}
        </Grid>
        <Grid item xs={8} md={6} lg={6}>
          <Typography className="typo2">
            Trusted by 150,000+ freelancers, agencies, and Fortune 500 companies
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
