import React from 'react';
import { Container, Grid, Typography, Link } from '@material-ui/core';
import logo from '../../Images/Logo.png';
import face from '../../Images/face.png';
import twt from '../../Images/twt.png';
import insta from '../../Images/insta.png';
import link from '../../Images/link.png';
import { useHistory } from 'react-router-dom';

import './footer.css';
export default function Footer() {
  const history = useHistory();

  function handleNavigate() {
    history.push(`/Terms`);
  }

  return (
    <Container>
      <Grid
        className="mobileView"
        container
        justify="flex-end"
        spacing={2}
        style={{
          //   display: 'flex',
          marginRight: '32px'
          //   justifyContent: 'space-evenly',
          // float: 'right'
          // marginTop: '100px'
        }}
      >
        <Grid item className="pointer" onClick={handleNavigate}>
          <p>Terms and Use </p>
        </Grid>
        <Grid item className="pointer">
          <p>Plans</p>
        </Grid>
        <Grid item className="pointer">
          <p>Blog</p>
        </Grid>
        <Grid item className="pointer">
          <p>Library</p>
        </Grid>
        <Grid item className="pointer">
          <p>Help</p>
        </Grid>
      </Grid>
      <Grid container className="mobileView" justify="space-between">
        <Grid item xs={6} md={3} lg={3}>
          <img className="pointer" src={logo} height="36px" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>
            <p className="pointer">Connect with us</p>
          </div>
          <div>
            <img className="pointer" src={face} />
          </div>
          <div>
            <img className="pointer" src={twt} />
          </div>
          <div>
            <img className="pointer" src={insta} />
          </div>
          <div>
            <img className="pointer" src={link} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
