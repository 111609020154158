import React, { useState } from 'react';
import { Container, Grid, Typography, Component } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './portfollio.css';
import v1 from '../../Images/v2_277.png';
import v2 from '../../Images/v2_278.png';
import v3 from '../../Images/v2_279.png';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { Button } from '@material-ui/core';
// import { Spring, animated } from 'react-spring/web.cjs';
import { useSpring, animated } from 'react-spring';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.34)',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '750px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '300px'
    }
  }
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func
};

export default function Portfollio() {
  const [slickData, setSlickData] = useState('');

  const Images = [
    {
      img: v3,
      info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      img: v1,
      info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      img: v2,
      info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
      img: v2,
      info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    }
  ];

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = (data) => {
    console.log('data:', data);
    setSlickData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setting = {
    className: 'center',
    // centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 1,
    speed: 500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const settings = {
    className: 'center',
    // centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Container>
      <Grid container justify="center" style={{ marginTop: '250px' }}>
        <Grid item>
          <Typography className="typo3">Portfolio</Typography>
          <p className="alignCenter">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div>
            <Slider {...settings}>
              {Images.map((i) => (
                <div>
                  {' '}
                  <img
                    onClick={() => handleOpen(i)}
                    src={i.img}
                    className="slickImg"
                    // height="300px"
                    width="95%"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Grid>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Grid item xs={12} md={12} lg={12}>
                <div>
                  <Slider {...setting}>
                    {/* {Images.map((i) => ( */}
                    <div>
                      {' '}
                      <img
                        onClick={handleOpen}
                        src={slickData.img}
                        className="slick2Img"
                        height="400px"
                        width="100%"
                      />
                    </div>
                    {/* ))} */}
                  </Slider>
                </div>
              </Grid>
              <div style={{ textAlign: 'right' }}>
                <Typography className="titleP">Portfolio Title</Typography>
                <p>{slickData.info}</p>
                <Button
                  variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: '#E5122E',
                    color: 'white',
                    fontSize: '12px',
                    textAlign: 'center',
                    height: '44px',
                    width: '120px'
                  }}
                >
                  <b> Visit Site</b>
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </Container>
  );
}
