import React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import icon1 from '../../Images/Setting_line_light.png';
import icon2 from '../../Images/Code.png';
import icon3 from '../../Images/Date_fill.png';
import Card from './card';
import './topcard.css';
export default function TopCards() {
  const cardsData = [
    {
      title: 'Formbutton - Easy popup forms',
      description:
        'Copy/paste the snippet to overlay an attractive popup form.',
      icon: icon3
    },
    {
      title: 'Simple HTML forms',
      description:
        'Just set your form’s action to our endpoint. No server code required.',
      icon: icon2
    },
    {
      title: 'Powerful JavaScript forms',
      description:
        'Use the Formspree CLI and React library. Deploy your production forms with CI/CD.',
      icon: icon1
    }
  ];
  return (
    <>
      <Container>
        <Grid container justify="center" style={{ marginTop: '200px' }}>
          {cardsData.map((data) => (
            <Card Data={data} />
          ))}
        </Grid>
      </Container>
    </>
  );
}
