import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Group9 from "../../Images/Group 9.png";
import "./main.css";
export default function main() {
  return (
    <React.Fragment>
      <Container>
        <Grid container justify="space-between" style={{ marginTop: "20px" }}>
          <Grid item xs={12} md={4} lg={4} style={{ marginTop: "100px" }}>
            <img src={Group9} className="lobImg" width="484px" />
          </Grid>
          <Grid item xs={12} md={5} lg={7} style={{ marginTop: "150px" }}>
            <Typography className="typo1">
              The form solution for any developer...
            </Typography>
            <p style={{ textAlign: "right", marginTop: "35px" }}>
              Use your own frontend code. Submit to our API. We'll handle the
              rest.
            </p>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#D30E28",
                color: "white",
                fontSize: "19px",
                float: "right",
                marginTop: "30px",
              }}
            >
              <b> Get Started</b>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
