import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Group9 from '../../Images/weblobster.png';
import Chield from '../../Images/Chield_fill.png';
import msg from '../../Images/Message_alt_fill.png';
import chat from '../../Images/Chat.png';
import share from '../../Images/Vector.png';

import './main2.css';
export default function main2() {
  return (
    <Container>
      <Grid container justify="space-between" style={{ marginTop: '50px' }}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography className="typo10">How it works</Typography>
          <p style={{ textAlign: 'center' }}>
            Here's what happens after your form is submitted using Javascript
            forms, HTML forms
          </p>
          <div
            style={{ display: 'flex', marginTop: '30px', marginRight: '7px' }}
          >
            <div className="urdu">
              <Typography className="typo5">
                Spam blocked, data validated
              </Typography>
              <p className="p">
                We validate your data server-side and use machine learning to
                protect you from spam
              </p>
            </div>
            <div className="hIcon" style={{ marginLeft: '5px' }}>
              <img src={Chield} />
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '40px' }}>
            <div className="urdu">
              <Typography className="typo5">
                Email notifications and auto-responses are sent
              </Typography>
              <p className="p">Our email templates are fully customizable</p>
            </div>
            <div className="hIcon">
              <img className="msgAlign" src={msg} />
            </div>
          </div>
          <div style={{ display: 'flex', marginRight: '10px' }}>
            <div className="urdu">
              <Typography className="typo5">
                Submissions are saved to the Formspree Inbox
              </Typography>
              <p className="p">
                View submissions, see daily analytics reports, and export to CSV
                or JSON
              </p>
            </div>
            <div className="hIcon">
              <img src={chat} />
            </div>
          </div>
          <div style={{ display: 'flex', marginRight: '20px' }}>
            <div className="urdu">
              <Typography className="typo5">
                3rd party integrations are triggered
              </Typography>
              <p className="p">
                With our direct integrations, we'll get your data where it needs
                to be - no Zapier required
              </p>
            </div>
            <div className="hIcon" style={{ marginLeft: '16px' }}>
              <img src={share} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          <img className="mirror" src={Group9} width="530px" height="572px" />
        </Grid>
      </Grid>
    </Container>
  );
}
