import React from 'react';
import { Container, Grid } from '@material-ui/core';
import PrimaryNavBar from '../components/navbar2/navBar2';
export default function navBar() {
  return (
    <>
      <Container>
        <PrimaryNavBar />
        <Grid container justify="center" style={{ marginTop: '50px' }}>
          <h2>Terms and Use</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis,
            minima delectus nesciunt quidem quia hic quaerat culpa illum
            praesentium nulla itaque quisquam, laborum quasi enim exercitationem
            voluptates ut expedita veniam. Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Officiis, minima delectus nesciunt
            quidem quia hic quaerat culpa illum praesentium nulla itaque
            quisquam, laborum quasi enim exercitationem voluptates ut expedita
            veniam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Officiis, minima delectus nesciunt quidem quia hic quaerat culpa
            illum praesentium nulla itaque quisquam, laborum quasi enim
            exercitationem voluptates ut expedita veniam. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Officiis, minima delectus
            nesciunt quidem quia hic quaerat culpa illum praesentium nulla
            itaque quisquam, laborum quasi enim exercitationem voluptates ut
            expedita veniam. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Officiis, minima delectus nesciunt quidem quia hic quaerat
            culpa illum praesentium nulla itaque quisquam, laborum quasi enim
            exercitationem voluptates ut expedita veniam.
          </p>
        </Grid>
      </Container>
    </>
  );
}
