import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Button from '@material-ui/core/Button';
// import { Link } from 'react-router';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Grid } from '@material-ui/core';

// import Button from "@material-ui/core/Button";

import NavBarLogo from '../../Images/Logo.png';

import './PrimaryNavBar.css';
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: '56%',
    left: '49%',
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  grow: {
    // flexGrow: 1,
  },
  title: {
    display: 'none',
    color: 'black',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      color: 'black',
      cursor: 'pointer'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#EEEEE9',
    '&:hover': {
      backgroundColor: '#EEEEE9'
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    color: 'black',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  navbar1: {
    padding: '10px',
    cursor: 'pointer'
  },
  paper: {
    position: 'relative',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: '0 0 4px',
    borderRadius: '10px',
    padding: theme.spacing(2, 4, 3)
  }
}));

export default function PrimaryNavBar() {
  const history = useHistory();

  function handleNavigate() {
    history.push(`/`);
  }

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <a className="anchor" href="#Home">
          <b> Home</b>
        </a>
      </MenuItem>
      <MenuItem>
        <a className="anchor" href="#plans">
          <b> Plans</b>
        </a>
      </MenuItem>
      <MenuItem>
        <a className="anchor" href="#blogs">
          <b> Blog</b>
        </a>
      </MenuItem>
      <MenuItem>
        <a className="anchor" href="#library">
          <b> Library</b>
        </a>
      </MenuItem>
      <MenuItem>
        <a className="anchor" href="#help">
          <b> Help</b>
        </a>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="white" style={{ boxShadow: 'none' }}>
        <Toolbar style={{ justifyContent: 'space-between', height: '68px' }}>
          {/* <Typography
            className={classes.title}
            variant="h6"
            noWrap
            onClick={handleHome}
          >
            Nursing Logo
          </Typography> */}

          <img
            className="logo"
            style={{ cursor: 'pointer' }}
            src={NavBarLogo}
            height="36px"
            onClick={handleNavigate}
          />

          {/* <Grid style={{ width: '10px' }}></Grid> */}

          <Grid
            className={classes.sectionDesktop}
            // style={{ marginRight: '8%' }}
          >
            <div className={classes.navbar1}>
              <h4 className="tt">
                {' '}
                <a className="anchor" href="#Home">
                  Home
                </a>
              </h4>
            </div>
            <div className={classes.navbar1}>
              <h4 className="tt">
                <a className="anchor" href="#plans">
                  Plans
                </a>
              </h4>
            </div>
            <div className={classes.navbar1}>
              <h4 className="tt">
                {' '}
                <a className="anchor" href="#blogs">
                  Blog
                </a>
              </h4>
            </div>
            <div className={classes.navbar1}>
              <h4 className="tt">
                {' '}
                <a className="anchor" href="#library">
                  Library
                </a>
              </h4>
            </div>
            <div className={classes.navbar1}>
              <h4 className="tt">
                {' '}
                <a className="anchor" href="#help">
                  Help
                </a>
              </h4>
            </div>
          </Grid>
          {/* <Grid style={{ width: '10px' }}></Grid> */}
          {/* <Grid alignItems="center" className={classes.sectionDesktop}>
            <div className={classes.navbar1}>
              {' '}
              <Button
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: '#E5122E',
                  color: 'white',
                  fontSize: '12px',
                  textAlign: 'center',
                  height: '44px',
                  width: '120px'
                }}
              >
                <b> Get Started</b>
              </Button>
            </div>

            <div className={classes.navbar1}>
              <Button
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: '#0E232D',
                  color: 'white',
                  fontSize: '12px',
                  textAlign: 'center',
                  height: '44px',
                  width: '120px'
                }}
              >
                <b> Sign In</b>
              </Button>
            </div>
          </Grid> */}

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
