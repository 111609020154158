import NavBar from '../components/navBar/navBar';
import Nav2 from '../components/navbar2/navBar2';
import Main1 from '../components/main1/main1';
import TopCards from '../components/topCards/topCards';
import BigCrad from '../components/bigCard/bigCrad';
import Portfollio from '../components/portfollio/portfollio';
import Main2 from '../components/main2/main2';
import Plans from '../components/Plans/plans';
import BigCard2 from '../components/BigCard2/bigCard2';
import Footer from '../components/Footer/footer';
import { Divider, Grid, Box, Container } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import vector from '../Images/Frame.png';
import vector2 from '../Images/frightDown.png';
import vector3 from '../Images/f4.png';

import '../App.css';
import { Group } from '@material-ui/icons';
function Home() {
  return (
    <>
      <Container>
        <Grid xs={12}>
          <Nav2 />
        </Grid>
      </Container>

      <Grid id="Home">
        <img src={vector} style={{ position: 'absolute', top: '0' }} />

        <Main1 />
        <img src={vector2} className="upperD" />
      </Grid>

      <Grid id="blogs">
        <TopCards />
      </Grid>
      <div className="bgC2"></div>

      <Grid>
        <BigCrad />
      </Grid>
      <Grid id="library">
        <Portfollio />
      </Grid>

      <div className="bgC"></div>

      <Grid id="help" style={{ marginTop: '400px' }}>
        <Main2 />
      </Grid>
      <Grid id="plans" style={{ marginTop: '180px' }}>
        <img src={vector2} className="lowerD2" />

        <Plans />
      </Grid>

      <Grid style={{ marginTop: '80px' }}>
        <img src={vector3} className="lowerD" />

        <BigCard2 className="index" />
      </Grid>

      <Grid style={{ marginTop: '120px', bottom: 0 }} className="index">
        <Footer />
      </Grid>
    </>
  );
}

export default Home;
