import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Terms from './Pages/terms_conditions';
import ReactGA from 'react-ga';
import RouterChangeTracker from './RouterChangeTracker';

function App() {
  const TRACKING_ID = 'UA-208194801-1'; // YOUR_OWN_TRACKING_ID

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/Terms" component={Terms} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
